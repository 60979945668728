.youtube-preview {
	position: relative;
}

.youtube-preview-icon-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.youtube-preview-icon {
	font-size: 4.5em;
	color: white;
	opacity: 0.8;
	transition: 0.2s;
}

.youtube-preview-icon-overlay:hover .youtube-preview-icon {
	color: red;
	opacity: 1;
}

.youtube-preview-title {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0.25em;
	display: flex;
	align-items: center;
	color: white;
	font-size: large;
	width: 100%;
}

.youtube-preview-title > img {
	margin-right: 0.5em;
}

.youtube-embed {
	position: relative;
}

.youtube-embed-overlay {
	position: absolute;
	display: none;
	pointer-events: none;

	bottom: calc(50% - 40px);
	left: calc(50% - 48px);
    background: #ff0000cc;
    border: 1px solid;
    padding: 1rem 0.25rem;
    border-radius: 10px;
	color: white;
}

.youtube-embed-overlay.show {
	display: block;
}

#yt-preview-modal {
	width: 95vw;
	top: 25vh;
	left: 2.5vw;
}

#yt-preview-modal .modal-content {
	box-shadow: none;
}

#yt-preview-modal .modal-body {
	height: 50vh;
}