/* general */

.tum-add-menu-close, .tum-menu-close {
	outline: none !important;
	top: 0px;
	right: 4px;
	position: absolute;
}

.b-product-side-icons .miniature-top-btn {
	transition: 0.2s;
	background-color: hsla(0,0%,100%,.75);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 1px;
}

.b-product-side-icons .miniature-top-btn:hover {
	background-color: #bdbdbd;
	text-decoration: none;
}

.mini-header-discount {
	transform: rotate(-45deg);
	font-size: 0.8rem;
	font-weight: bold;
	transition: .2s;
	background-color: hsla(0,0%,100%,.75);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 1px;
}

.mini-header-hide-video {
	top: 10px;
	left: 5px;
	position: absolute;
	font-weight: bold;
	transition: .2s;
	border-radius: 50%;
	font-size: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 1px;
	z-index: 5;
	text-decoration: none;
	opacity: 0.75;
}

.mini-header-hide-video:hover {
	text-decoration: none;
	opacity: 1;
}

.mini-header-hide-video .icon {
	background-color: black;
	color: white;
	border-radius: 50%;
}

.mini-header-hide-video .text {
	background-color: white;
	color: black;
	padding: 0 0.2em;
	border-radius: 3px;
	z-index: 1;
	position: relative;
	left: -5px;
}

.mini-header-show-video {
	top: calc(35% - 15px);
	left: calc(50% - 32px);
	position: absolute;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 1px;
	z-index: 8;

	background-color: white;
	color: black;
	opacity: 0.8;
	transition: 0.2s;
	padding: 1rem 0.25rem;
    border-radius: 10px;
}

.mini-header-show-video:hover {
	text-decoration: none;
	background-color: red;
	opacity: 1;
	color: white;
}

.youtube-embed-overlay {
	position: absolute;
	display: none;
	pointer-events: none;

	bottom: calc(50% - 40px);
	left: calc(50% - 48px);
    background: #ff0000cc;
    border: 1px solid;
    padding: 1rem 0.25rem;
    border-radius: 10px;
	color: white;
}

.large-miniature .mini-header-show-video {
	left: initial;
	right: 8px;
}

.min-product-annotation-collapsed {
	max-height: 38px !important;
}

.tum-add-favorite i, .tum-del-favorite i {
	font-size: 1.2rem;
}

.mini-firmInfo {
	/* 100% - отступ - ширина иконки - произвольный отступ слева */
	max-width: calc(100% - 0.7rem - 22px);
}

.b-product-side-icons {
	position: absolute;
	right: 8px;
	top: 10px;
	z-index: 4;
}

.b-product-side-icons .tum-set-rate {
	position: relative;
	bottom: 1px;
}

/* /general */

/* images */

.b-product-img {
	width: 240px;
	height: 300px;
}

.b-product-img.out {
	width: auto;
}

.b-product-img img {
	width: auto;
    height: 100%;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.mini-product-video {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
	width: 100%;
}

.b-product-img-link {
	display: block
}

.mini-images-pages {
	margin-top: 1px;
	display: flex;
	width: 50%;
	justify-content: center;
}

.mini-images-page {
	height: 5px;
	margin: 0 3px;
	background-color: gray;
	min-width: 5px;
	border-radius: 5px;
}

.mini-images-page.active {
	background-color: #ca1f2f;
	width: 15px;
}

.mini-images-page-area {
	position: relative;
	height: 100%;
	flex-grow: 1;
}

.mini-images-swipeArea {
	touch-action: pan-y;
	z-index: 4;
}

.mini-pages-area {
	z-index: 3;
	height: 100%;
}

/* /images */

/* additional info miniature */

.tum-anyText-lastProductSlide {
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

/* /additional info miniature */

/* link under miniature */
.tum-card-linkUnder {
	white-space: nowrap !important;
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	padding: 3px 0px 0px 8px;
}

/* /link under miniature */

/* owl carousel fix */

.owl-item > div {
	max-width: 100%;
}

.owl-carousel {
	margin-left: 0px;
}

/* /owl carousel fix */

/* стили из админки ("срочные правки") */

.product-miniature-menu .miniature-menu-link {
    display: contents;
}

/* /стили из админки */

/* adult content warning */

.adult-image-wrapper .svg-icon {
	width: 50px;
	height: 50px;
}

.adult-image-wrapper {
	display:none;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: hsla(0,0%,100%,.2);
	cursor: pointer;
	z-index: 2;
}

._adult-product .adult-image-wrapper {
	display: flex;
}

._adult-product .catalog-index {
	opacity: 0;
}

.product-card._adult-product .tum-midl-img {
	filter: blur(30px);
}

.product-card._adult-product .b-product-img {
	overflow: hidden;
}

/* /adult content warning */

/* ad */

.miniature-label, .product-label, .miniature-adMark {
	border-radius: 10px;
	padding: 0.1rem 0.3rem;
    font-size: 60%;
}

.miniature-label, .product-label {
	background-image: linear-gradient(to right, #d3ece6,#ebfe48);
    text-transform: uppercase;
    color: black;
    font-size: .6rem;
    font-weight: 600;
}

.miniature-adMark {
	right: 5px;
}

.miniature-label {
	left: 5px;
}

/* /ad */

.catalog-index-1, 
.catalog-index-2, 
.catalog-index-3, 
.catalog-index-4, 
.catalog-index-5, 
.catalog-index-6, 
.catalog-index-7, 
.catalog-index-8, 
.catalog-index-9, 
.catalog-index-0 {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	color: white;
	text-align: center;
	z-index: 2;
}

	.catalog-index-1 div, 
	.catalog-index-2 div, 
	.catalog-index-3 div, 
	.catalog-index-4 div, 
	.catalog-index-5 div, 
	.catalog-index-6 div, 
	.catalog-index-7 div, 
	.catalog-index-8 div, 
	.catalog-index-9 div, 
	.catalog-index-0 div {
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
	}

.catalog-index-1 {
	background-color: rgba(51, 51, 51, 0.807843137254902);
}

.catalog-index-2 {
	background-color: rgba(44, 117, 255, 0.807843137254902);
}

.catalog-index-3 {
	background-color: rgba(153, 153, 153, 0.807843137254902);
	color: black !important;
}

.catalog-index-4 {
	background-color: rgba(128, 0, 128, 0.807843137254902);
}

.catalog-index-5 {
	background-color: rgba(165, 42, 42, 0.807843137254902);
}

.catalog-index-6 {
	background-color: rgba(128, 128, 0, 0.807843137254902);
}

.catalog-index-7 {
	background-color: rgba(255, 128, 0, 0.807843137254902);
	color: black !important;
}

.catalog-index-8 {
	background-color: rgba(0, 0, 128, 0.807843137254902);
}

.catalog-index-9 {
	background-color: rgba(0, 128, 0, 0.807843137254902);
}

.catalog-index-0 {
	background-color: rgba(255, 0, 0, 0.807843137254902);
}

.b-product-annotation > i + span.small {
	padding-left: 37px;
	display: block;
}

/* menu */

@media (min-width: 768px) {
	.mini-menu-upper {
		border-left: 10px solid #d9d9d9;
		margin-left: -0.45rem;
	}

	.mini-menu-lower {
		box-shadow: 1px -1px 7px 4px #9b9b9b; 
		margin: 0 0 0.5rem 0.75rem; 
		padding: 0.25rem; 
		background: #8080801a;
	}

	.product-miniature-menu {
		padding-right: .5rem;
		margin-right: 1rem;
		border-radius: 5px;
		width: 90%;
	}
}

@media (max-width: 767px) {
	.tum-add-menu-close {
		margin-right: 0.5rem;
	}
}
