.tum-search-otherCities {
	margin-top: 25px;
	margin-bottom: 5px;
	padding: 10px;
	border: solid 1px #cccccc;
	border-radius: 10px;
}

.tum-search-otherCity {
	margin-top: 5px;
}

.tum-search-otherCitySlider .visibility-sliders {
	border-top: solid 1px #cccccc;
	border-bottom: solid 1px #cccccc;
	margin-top: 1em;
	margin-bottom: 1em;
	padding-top: 5px;
}

.tum-search-showOtherCitySlider {
	white-space: nowrap;
}
