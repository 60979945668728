.previewsCont p img {
	margin-right: 1.4rem !important;
}
.as-u5-cropButtons a.btn {
	padding: .375rem .75rem !important;
	font-size: 1rem !important;
}
.as-u5-uploadButton.btn-xs {
	padding: .375rem .75rem;
	font-size: 1rem;
}

.tum-review-logo video {
	max-width: 200px;
}

.tum-preview-user-info {
	position: relative;
	display: flex;
}

.tum-previews-midl-img {
	background-color: #CCC;
}

@media (max-width: 767px) {
	.tum-preview-user-info {
		display: block;
	}

	.tum-preview-logo .tum-previews-midl-img {
		margin: 0 auto;
	}

	.tum-desc-txt {
		overflow: hidden;
		width: calc(100% - 20px);
		transition: 0.5s;
		display: inline-block;
	}
}

@media (min-width: 768px) {
	.tum-preview-logo {
		width: 200px;
	}

	.tum-preview-info {
		width: calc(100% - 200px);
	}

	.tum-desc-txt {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.tum-preview-name {
	color: black;
}

.tum-preview-username {
	right: 0;
}


.tum-previews-youtube {
	width: 200px;
	height: 113px;
}

@media (max-width: 575px) {
	.tum-previews-youtube {
		width: 144px;
		height: 81px;
	}
}

.tum-preview-readmore {
	overflow: hidden;
	width: calc(100% - 20px);
	transition: 0.5s;
	display: inline-block;
}
