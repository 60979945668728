.ctuCloud, .tum-scrol-head {
	overflow-x: auto;
	height: 46px;
	padding: 4px 0px 0px;
	overflow-y: hidden;
	margin: 0.25rem 0rem;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.ctuCloud, .tum-scrol-head .b-aside-itemShowFilterBlock {
    font-size: .875rem;
}

.ctuCloud .ctuCloud-ctuName {
	max-width: 200px;
	display: inline-block;
}

@media (min-width: 768px) {
	.ctuCloud {
		scrollbar-width: none;
	}

	.ctuCloud::-webkit-scrollbar {
		display: none;
    }

	.ctuCloud-back:hover, .ctuCloud-forward:hover {
		text-decoration: none;
	}

	.tum-scrol-head {
		scrollbar-width: thin;
	}

	.tum-scrol-head::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        -webkit-border-radius: 5px;
        border-radius: 5px;
    }

    .tum-scrol-head::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
        -webkit-border-radius: 10px;
        border-radius: 5px;
    }

    .tum-scrol-head::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #d1d1d1;
        height: 3px;
    }

    .tum-scrol-head::-webkit-scrollbar-corner {
        display: none;
        height: 0px;
        width: 0px;
    }
}