.modal-footer .btn-primary {
	background-color: #ca1f2f;
	border-color: #ca1f2f;
}

.tum-home-lastProductSlide > ul {
	padding-left: 1.5rem !important;
	padding-right: 1.5rem !important;
}

.catalog-slider-container{
	padding: 10px;
	background-color: #f4f4f4;
	overflow: hidden;
	min-height: 266px;
}

@media (max-width: 1025px) {
	.catalog-slider-container {
		min-height: 235px;
	}
}

@media (max-width: 415px) {
	.catalog-slider-container {
		min-height: 202px;
	}
}

@media (max-width: 376px) {
	.catalog-slider-container {
		min-height: 190px;
	}
}

@media (max-width: 321px) {
	.catalog-slider-container {
		min-height: 172px;
	}
}

.catalog-slider-container .owl-dots .owl-dot {
	outline: none !important;
}
.catalog-slider-container .owl-theme .owl-dots .owl-dot.active span {
	background: #3A5FAB;
}
.tum-pricedesc {
	overflow: hidden;
	width: calc(100% - 15px);
}

.cursor-pointer {
	cursor: pointer !important;
}

.tum-pricedesc + a {
	margin-top: -17px;
	padding-top: 2px;
	height: 0px;
	float: right;
}

.tum-home-productslider {
	min-width: 300px;
	position: relative;
	z-index: 1;
}

	.tum-home-productslider.bordered {
		border-top: 2px solid #ca1f2f;
	}

.tum-home-homePageItems {
	min-width: 66%;
	text-align: center;
}

.search-links {
	padding-left: 0px;
	margin-top: 0px;
	display: inline-block;
	text-align: left;
}

.tum-home-lastProductSlide {
	justify-content: center;
	align-items: center;
}

	.tum-home-lastProductSlide ul {
		list-style: none;
	}

.alignSync {
	visibility: hidden;
}

.logoDot {
	font-size: 18px;
	line-height: normal;
	height: 10px;
	overflow: visible;
	display: inline-block;
}

	.logoDot i {
		background: white;
		height: 25px;
		padding: 0px 3px;
	}

@media (max-width: 767px) {
	
	.logoDot {
		font-size: 12px;
	}

	.logoDot i {
		font-size: 12px;
		height: 20px;
	}

	tum-mob-menu-toggler.tum-search-icon {
		display: none;
	}

	navbar-brand {
		display: none;
	}
}

.home-spaceAboveSearch {
	height: 8vh;
}

.home-spaceBelowSearch {
	height: 8vh;
}

.home-mob-catalog {
	height: 12vh;
}

.home-search .dropdown-toggle {
	border-right: 0;
}

.owl-theme .owl-dots {
	white-space: nowrap;
	overflow-x: auto;
}
@media(max-width: 425px){
	.owl-theme .owl-dots .owl-dot span {
		width: 13px !important;
		height: 2px !important;
		margin: 5px 2px !important;
	}
}
@media(min-width: 426px) and (max-width: 575px) {
	.owl-theme .owl-dots .owl-dot span {
		width: 17px !important;
		height: 2px !important;
		margin: 5px 4px !important;
	}
}
@media(min-width: 576px) and (max-width: 992px) {
	.owl-theme .owl-dots .owl-dot span {
		width: 25px !important;
		height: 3px !important;
		margin: 5px 4px !important;
	}
}
@media(min-width: 993px) {
	.owl-theme .owl-dots .owl-dot span {
		width: 50px !important;
		height: 3px !important;
		margin: 5px 5px !important;
	}
}

.mob-h1 {
	line-height: 1.2;
}

.home-previews {
	min-height: 208px;
}

/* стили шаблона home-txt1 */
.home-txt1 .tu-show-searh, .home-txt1 .home-btn {
	font-size:0.9em; margin: 0.2rem 0rem; padding: 0.10rem 0.5rem;
}

.home-txt1 .home-h2 {
	margin-top: 18px; 
	color: #555; 
	font-size: 13px; 
	font-weight: 700;
}

.home-txt1 .slider-slide {
	flex: 1 1 215px;
}

@media (min-width: 768px) {
	.home-news .slider-list {
		-ms-overflow-style: initial;
		scrollbar-width: thin;
	}

	.home-news .slider-list::-webkit-scrollbar {
		display: block;
	}
	
	.home-news .slider-list::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0); 
		-webkit-border-radius: 5px;
		border-radius: 5px;
	}

	.home-news .slider-list::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0); 
		-webkit-border-radius: 10px;
		border-radius: 5px;
	}

	.home-news .slider-list::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background: #d1d1d1;
		height: 3px;
	}

	.tum-scrol-head::-webkit-scrollbar-corner { display: none; height: 0px; width: 0px; }
}

.home-news .news-item {
	white-space: normal; 
	width: 100%; 
	border-radius: 10px; 
	box-shadow: 1px 1px 5px rgb(0 0 0 / 70%);
}